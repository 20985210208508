import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { keyframes, css } from "@emotion/core"
import theme from "../../gatsby-plugin-theme-ui"

export const StyledSpan = styled.span`
display: none;
font-size:0.7rem;
font-weight:900;
color:white;
padding:0.1rem;
padding-left:0.5rem;
padding-right:0.5rem;

background-color:lightblue;
border-radius:2rem;
opacity:0.8;
margin-left:0.2rem;


@media only screen and (max-width: ${theme.breakpoints[1]}) {
   display:inline-block;
  };
`
export const Overlay = styled.span`
position: fixed;
top: 0;
left: 0;
width: 0;
height: 0;
opacity:0;
background-color:transparent; 
@media only screen and (max-width: ${theme.breakpoints[1]}) {
  position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0,0,0,0.5); 
  };
`

export const StyledMiddle = styled.span`


@media only screen and (max-width: ${theme.breakpoints[1]}) {
  position: absolute;
  left:10px;
  width:100%;
  display:flex;
  justify-content:center;
  padding:2rem;
  };
`

export default function Template(props) {
  const [style, setStyle] = React.useState(false)

  var linkStyle
  var displayStyle
  if (style) {
    linkStyle = {
      color: "#ed1212",
      cursor: "pointer",
      fontWeight: "700",
      textDecoration: "none",
    }
    displayStyle = {
      opacity: 1,
      position: "absolute",
      transform: "translate(-10px,1.2rem)",
      maxWidth:'90%',
      backgroundColor: "white",
      boxShadow:'3px 5px 10px rgba(0, 0, 0, 0.4)', 
      padding:10,
      borderRadius:5,
      zIndex:4
    }
  } else {
    linkStyle = { color: "#fa9818", fontWeight: "700", textDecoration: "none" }
    displayStyle = { opacity: 0, height:0, overflow:'hidden', width:0, position: "absolute",  }
  }

  const toggleHover = x => {
    setStyle(x)
  }

  return (
    <span>
      <Overlay style={{opacity:style ?1:0, zIndex:style?3:-3}} onClick={()=>toggleHover(false)}></Overlay>
      <span  onMouseEnter={() => toggleHover(true)}
    onMouseLeave={() => toggleHover(false)} >
    <Link to={props.to} itemProp="url" style={linkStyle}>
    {props.inlineContent}
      </Link>
      </span><StyledSpan onClick={()=>toggleHover(true)}>?</StyledSpan>
     
    <StyledMiddle onClick={()=>toggleHover(false)}><span style={displayStyle}>{props.hoverContent}</span></StyledMiddle>

   
  
    </span>
  )
}
