import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { keyframes, css } from "@emotion/core"
import theme from "../../gatsby-plugin-theme-ui"
import pSBC  from 'shade-blend-color'
import { StaticImage } from "gatsby-plugin-image"
var width = 300

export const StyledSpan = styled.span`
@media only screen and (min-width: ${theme.breakpoints[1]}) {
    min-height: 70vh;
    height: auto;
  };
`

const fillerArray ={
de:{
 heading:'Textanalyse',
 instruction:'Wähl eine Karte aus, um den Text zu analysieren.',
 ndeklination:{sub:'Grammatik', main:'n-Deklination (schwache Nomen)'},
 iartikel:{sub:'Grammatik', main:'Indefiniter Artikel'},
 dartikel:{sub:'Grammatik', main:'Definiter Artikel'},
 nebensaetze:{sub:'Grammatik', main:'Nebensätze'},
 vpassiv:{sub:'Grammatik', main:'Vorgangspassiv'},
 praeteritum:{sub:'Grammatik', main:'Präteritum'},
 rverben:{sub:'Grammatik', main:'reflexive Verben'},
 pundvk:{sub:'Grammatik', main:'Präpositionen und verlangten Kasus'},
 pundvk:{sub:'Grammatik', main:'trennbares Verb'},
 
 
 
}
}

var backgroundColor = '#4b4b4b86'
var backgroundColorSelected = '#fa9818'


export default function Template(props) {

  return (
    <span>
        <h3>{fillerArray[props.frontmatter.learn].heading}</h3>
        <h5 style={{marginTop:0, color:props.frontmatter.selected ? 'gray':'red'}}>{fillerArray[props.frontmatter.learn].instruction}</h5>
   <div style={{display:'flex', flexDirection:'row', overflowX:'scroll',  }}>
{props.frontmatter.selected ?   <div style={{textDecoration: "none",}} to={props.frontmatter.slug+'/'+props.frontmatter.options[props.frontmatter.options.indexOf(props.frontmatter.selected)]} itemProp="url" >
     <div style={{width:width, height:width/1.5, margin:20, borderRadius:width/25, overflow:'hidden', backgroundImage:props.frontmatter.options[props.frontmatter.options.indexOf(props.frontmatter.selected)] !==props.frontmatter.selected ? `linear-gradient(90deg, ${backgroundColor},${ pSBC(0.20,backgroundColor)}, ${ pSBC(0.35,backgroundColor)})`:`linear-gradient(90deg, ${backgroundColorSelected},${ pSBC(0.20,backgroundColorSelected)}, ${ pSBC(0.35,backgroundColorSelected)})`}}>
     <StaticImage src="./eye.png" alt={'Eye Icon'} style={{color:'#fff', opacity:0.8, margin:20, marginBottom:0}} placeholder={'blurred'} fit={'contain'} height={55} />
    <div style={{fontSize:18,fontWeight:'bold', margin:0, marginLeft:20, color:'#fff', opacity:0.8}}>{fillerArray[props.frontmatter.learn][props.frontmatter.selected].sub}</div>
    <div style={{lineHeight:1.2,fontWeight:'bold', fontSize:24, margin:0, marginLeft:20,marginTop:5, color:'#fff', opacity:0.8}}>{fillerArray[props.frontmatter.learn][props.frontmatter.selected].main}</div>
     </div>
    </div>:null}


   { (props.frontmatter.options).map((item, i) => {
     if(item !==props.frontmatter.selected){
      return (
        <Link style={{textDecoration: "none",}} to={props.frontmatter.slug+'/'+item} itemProp="url" key={i}>
        <div style={{width:width, height:width/1.5, margin:20, borderRadius:width/25, overflow:'hidden', backgroundImage:item !==props.frontmatter.selected ? `linear-gradient(90deg, ${backgroundColor},${ pSBC(0.20,backgroundColor)}, ${ pSBC(0.35,backgroundColor)})`:`linear-gradient(90deg, ${backgroundColorSelected},${ pSBC(0.20,backgroundColorSelected)}, ${ pSBC(0.35,backgroundColorSelected)})`}}>
        <StaticImage src="./eye.png" alt={'Eye Icon'} style={{color:'#fff', opacity:0.8, margin:20, marginBottom:0}} placeholder={'blurred'} fit={'contain'} height={55} />
    <div style={{fontSize:18,fontWeight:'bold', margin:0, marginLeft:20, color:'#fff', opacity:0.8}}>{fillerArray[props.frontmatter.learn][item].sub}</div>
    <div style={{lineHeight:1.2,fontWeight:'bold', fontSize:24, margin:0, marginLeft:20,marginTop:5, color:'#fff', opacity:0.8}}>{fillerArray[props.frontmatter.learn][item].main}</div>

        
        </div>
       </Link>
       )

     }

   }
  
)}

   </div>


<div style={{width:'100%', height:50}}></div>
    </span>
  )
}
