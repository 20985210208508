import React from "react"
import { graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import InlineHover from '../components/mdx/InlineHover.js'
import OptionsSelector from '../components/mdx/OptionsSelector.js'
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  const shortcodes = { InlineHover,OptionsSelector}
//const blogHeaderImage = require(`../assets/images/articles/${frontmatter.learn}/${frontmatter.image}`)
  
//<header style={{backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${blogHeaderImage})`}}>
 
//<br></br>
//<br></br>
//<h2>{frontmatter.title}</h2>
//<br></br>
//<br></br>
//<br></br>
//<br></br>

//</header>

return (
  <Layout  location={frontmatter.slug} title={'Duovocal.com'}>

          <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        lang={frontmatter.know}
        keywords={frontmatter.keywords}
        canonical={frontmatter.canonical?`https://duovocal.com/${frontmatter.canonical}`:null}
        slug={frontmatter.slug}
      />
    <article   className="blog-post"
        itemScope
        itemType="http://schema.org/Article">

<header>
          <h1 itemProp="headline">{frontmatter.title}</h1>
          <p><span>Last Updated </span>{frontmatter.date}</p>
        </header>
        <MDXProvider  components={shortcodes}>
        <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
        </MDXProvider>
        <hr />
        <footer>
          {frontmatter.legal !=="true"?
     <Bio />:null}
  
     
        </footer>

    </article>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        description
        keywords
        title
        image
        learn
        know
        legal
        options
        selected
        canonical
      }
    }
  }
`